import Vue from 'vue';

const firebaseConfig = {
    apiKey: 'AIzaSyCi4d7uZARjlB6er5PSlDc_Z6A39m7CVKs',
    authDomain: 'one-tech-digital.firebaseapp.com',
    databaseURL: 'https://one-tech-digital-default-rtdb.asia-southeast1.firebasedatabase.app',
    projectId: 'one-tech-digital',
    storageBucket: 'one-tech-digital.appspot.com',
    messagingSenderId: '718316872257',
    appId: '1:718316872257:web:7ab1fe88e8a3e4876fdcc0',
};

Vue.mixin({
    data: function () {
        return {
            get companyName() {
                return 'ONE TECH DIGITAL'; //公司名稱
            },
            get regFormDomain() {
                return 'https://onetechdigital.xyz/';
            },
        };
    },
});

//  溫馨提示：建議使用較淺的顏色
//  歡迎回來的背景顏色
const panelColor = '#e8f2fa';

//  溫馨提示：建議使用較深的顏色
//  側邊欄的背景顏色
const sideBarColor = '#000';

//        /||\
//       / || \
//      |------|
//      |  ||  |
//      |  ||  |
//      |  ||  |
//      |  ||  |
//       \ || /
//  不用理 \||/
//        /||\
//       / || \
export { firebaseConfig, panelColor, sideBarColor };
